import React from 'react';
import './../css/Header.css'
const Header = () => {
    return (
        <header>
            <div className="container">
                Chat Messages
                <p>(Please upload the whatsapp chat export file only)</p>
            </div>
        </header>
    );
};

export default Header;
